<template>
  <div class="antdVue padding" :style="themeColorInit(themeColorConfig)">
    <!---弹性布局flex布局开始-->
    <div class="flex benben-flex-layout width_fullscreen flex-wrap  heartcheckManagementtextChecklist_flex_0">
      <div class="flex  flex-wrap align-center heartcheckManagementtextChecklist_fd0_0" isAlone="false">
        <a-form-model ref="formRef1687328925717" class="benben-flex-form heartcheckManagementtextChecklist_fd0_0_c0"
          label-align="right" layout="horizontal" :label-col="{ span: 4, offset: 0 }"
          :wrapper-col="{ span: 20, offset: 0 }">
          <div class="flex align-center width_fullscreen heartcheckManagementtextChecklist_fd0_0_c0_c0">
            <div class="flex-sub">
              <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                <a-select v-model="queryParam.checktype" style="width: 158px;" @change="handleChange">
                  <a-select-opt-group v-for="item in checktypeList" :key="item.id">
                    <span slot="label">
                      <a-icon :type="item.type | iconType" />
                      {{ item.label }}
                    </span>
                    <a-select-option v-for="item1 in item.group" :key="item1.id" :value="item1.name">
                      {{ item1.label }}
                    </a-select-option>
                  </a-select-opt-group>
                </a-select>
              </a-form-model-item>
            </div>
            <div class="flex-sub">
              <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                <a-input class="heartcheckManagementtextChecklist_fd0_0_c0_c0_c1_c0" v-model="queryParam.account"
                  :disabled="false" :max-length="-1" placeholder="账号" :allow-clear="true" size="default">
                </a-input>
              </a-form-model-item>
            </div>
            <div class="flex-sub">
              <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                <a-input class="heartcheckManagementtextChecklist_fd0_0_c0_c0_c2_c0" v-model="queryParam.nickName"
                  :disabled="false" :max-length="-1" placeholder="昵称" :allow-clear="true" size="default">
                </a-input>
              </a-form-model-item>
            </div>
            <div class="flex-sub">
              <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                <a-input class="heartcheckManagementtextChecklist_fd0_0_c0_c0_c3_c0" v-model="queryParam.textContent"
                  :disabled="false" :max-length="-1" placeholder="文字内容" :allow-clear="true" size="default">
                </a-input>
              </a-form-model-item>
            </div>
            <div class="flex-sub">
              <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                <simple-select :disabled="false" placeholder="审核状态" mode="default" v-mode="queryParam.checkstatus"
                  :options="checkstatusList" :field-names='{ "label": "name", "value": "aid", "children": "child" }'>
                </simple-select>
              </a-form-model-item>
            </div>
            <div class="flex-sub">
              <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                <a-config-provider>
                  <a-date-picker class="heartcheckManagementtextChecklist_fd0_0_c0_c0_c5_c0"
                    v-model="queryParam.createtime" :disabled="false" :allow-clear="true" size="default"
                    placeholder="创建时间" value-format="YYYY-MM-DD" format="YYYY-MM-DD">
                  </a-date-picker>
                </a-config-provider>
              </a-form-model-item>
            </div>
            <div class="flex-sub">
              <a-form-model-item need="false" :rules="[]" :colon="false" label="">
                <a-input class="heartcheckManagementtextChecklist_fd0_0_c0_c1_c0_c0" v-model="queryParam.handler"
                  :disabled="false" :max-length="-1" placeholder="处理人" :allow-clear="true" size="default">
                </a-input>
              </a-form-model-item>
            </div>
            <div class="flex-sub">
              <div class="flex align-center flex-wrap align-center heartcheckManagementtextChecklist_fd0_0_c0_c1_c5_c0"
                isAlone="false">
                <a-button class="margin-right" @click="handleQuery" :disabled="false" type="primary">搜索</a-button>
                <a-button class="" :disabled="false" @click="resetQuery">重置</a-button>
              </div>
            </div>
          </div>
        </a-form-model>

      </div>

    </div>
    <!---弹性布局flex布局结束-->

    <!---弹性布局flex布局开始-->
    <div class="flex bg-white benben-flex-layout width_fullscreen flex-wrap padding-tb">
      <div class="flex align-center heartcheckManagementtextChecklist_fd1_0" isAlone="false">
        <div class="flex padding-right  flex-wrap align-center heartcheckManagementtextChecklist_fd1_0_c0"
          isAlone="false">
          <a-button :disabled="false" type="primary">批量审核成功</a-button>
        </div>
        <div class="flex padding-right flex-wrap align-center heartcheckManagementtextChecklist_fd1_0_c1" isAlone="false">
          <a-button class="heartcheckManagementtextChecklist_fd1_0_c1_c0" :disabled="false">批量审核失败</a-button>
        </div>

        <div class="flex margin-left-lg flex-wrap align-center heartcheckManagementtextChecklist_fd1_0_c2"
          isAlone="false">
          <a-button :disabled="false" type="primary">导出</a-button>
        </div>

        <div class="flex  flex-wrap align-center heartcheckManagementtextChecklist_fd1_0_c3" isAlone="false">

        </div>


      </div>

    </div>
    <!---弹性布局flex布局结束-->

    <!---弹性布局flex布局开始-->
    <div class="flex benben-flex-layout width_fullscreen flex-wrap">
      <a-table tableLayout="fixed" :bordered="true" :scroll="{ y: 500 }" size="small" :pagination="false"
        :data-source="data" :columns="columns" :row-key="record => record.aid">
        <template slot="content" slot-scope="text,record,index"><span>{{ text }}</span></template>
        <template slot="status" slot-scope="text,record,index">
          <a-tag color="#108ee9">{{ text }}</a-tag>
        </template>
        <span slot="operation" slot-scope="text, record">
          <div class="margin-bottom-xs">
            <a @click="$refs.createForm.handleUpdate(record, undefined)"
              v-hasPermi="['heart:checkManagement:picChecklist']" class="text-green">
              查看详情
            </a>
          </div>
          <a-popconfirm title="确定要审核通过?" @confirm="handleCheck(record, 1)">
            <a href="javascript:void(0)" v-hasPermi="['heart:checkManagement:picChecklist']" class="text-green">
              审核通过
            </a>
          </a-popconfirm>
          <a-divider type="vertical" v-hasPermi="['heart:checkManagement:picChecklist']" />
          <a-popconfirm title="确定要审核拒绝?" @confirm="handleCheck(record, 0)">
            <a href="javascript:void(0)" v-hasPermi="['heart:checkManagement:picChecklist']" class="text-red">
              审核拒绝
            </a>
          </a-popconfirm>
        </span>
      </a-table>
    </div>
    <!---弹性布局flex布局结束-->
  </div>
</template>

<script>
import {
  receive,
  benbenSelectDiy,
  benbenFlexNumberBox,
  scrollList,
  treeSelect,
  simpleSelect,
  multipleSelect,
  cascaderForm,
  checkboxForm,
  switchForm,
  messageReply,
  rangePicker,
  handSignature,
  mixinAddTableItem,
  editorForm,
  qiunDataCharts,
  copyText,
  mixinTableItemChange,
  in_array,
  themeColorInit,
  getUrlKey,
  clearDataPass,
  isEmpty
} from '@/assets/diypaaspc/diy-pass-pc.js'
import {
  provinceForm
} from '@/assets/diypaaspc/province_area.js'
import request from '@/utils/request'
export default {
  name: "goods",
  components: {
    qiunDataCharts,
    benbenSelectDiy,
    //            productSelect, 
    benbenFlexNumberBox,
    scrollList,
    treeSelect,
    simpleSelect,
    messageReply,
    rangePicker,
    multipleSelect,
    provinceForm,
    editorForm,
    cascaderForm,
    switchForm,
    checkboxForm,
    //            uploadImage,
    //            uploadFile,
    //            uploadMedia,
    //            uploadSingleFile,
    handSignature
  },
  data() {
    receive(this);
    const themeColorConfig = {
      "presetApi": {
        "getClassifyOne": "",
        "getClassifyTow": "",
        "getClassifyShop": ""
      },
      "themeType": 0,
      "themeConfig": [{
        "colorConfig": ["#333333", "#666666", "#999999", "#ffffff", "#1178F0", "#F02325", "#FC9C3A", "#4DC777", "#F2CC98", "#F2CC66"],
        "bgColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#FC9fff", "#E6F333", "#E6F1FE", "linear-gradient(301deg, #EBBA7B 0%, #F8D9AD 100%)", "linear-gradient(180deg, #F5588E 0%, #F0403C 100%)", "linear-gradient(179deg, #FFD033 0%, #F4890E 100%)"],
        "bdColorConfig": ["#EBEDF8", "#FFFFFF", "#1178F0", "#FC9C3A", "#333333", "#666666", "#999999", "#dddddd", "#1178F0", "#F02325"]
      }]
    };
    return {
      loading: false,
      total: 0,
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        checktype: undefined,
        checkstatus: '',
        account: '',
        status: '',
        nickName: '',
        textContent: '',
        createtime: '',
        handler: ''
      },
      checkstatusList: [
        {
          aid: 1,
          name: '等待审核'
        }, {
          aid: 2,
          name: '审核失败'
        }, {
          aid: 3,
          name: '审核成功'
        }
      ],
      // 1 用户信息：用户头像审核      昵称审核       个人简介审核
      // 2 粉丝群信息：粉丝群头像审核  粉丝群名称审核  粉丝团名称审核    粉丝群
      // 3 直播信息：直播预告审核      直播间封面     直播间标题        直播间介绍审核       
      // 4 其他：星座名称审核    公告审核
      checktypeList: [
        {
          id: 11,
          label: '用户信息',
          type: 'user',
          slot: 'label',
          group: [
            {
              id: 1,
              label: '用户头像审核'
            }, {
              id: 2,
              label: '昵称审核'
            }, {
              id: 3,
              label: '个人简介审核'
            },
          ]
        }, {
          id: 12,
          label: '粉丝群信息',
          slot: 'label',
          type: 'fans',
          group: [
            {
              id: 12,
              label: '粉丝群头像审核'
            }, {
              id: 13,
              label: '粉丝群审核'
            }, {
              id: 14,
              label: '粉丝团名称审核'
            },
          ]
        }, {
          id: 13,
          label: '直播信息',
          slot: 'label',
          type: 'Live',
          group: [{
            id: 8,
            label: '直播预告审核'
          }, {
            id: 9,
            label: '直播间封面'
          }, {
            id: 10,
            label: '直播间标题'
          }, {
            id: 11,
            label: '直播间介绍审核'
          }]
        }, {
          id: 14,
          label: '其他',
          slot: 'label',
          type: 'other',
          group: [{
            id: 15,
            label: '星座名称审核'
          }, {
            id: 16,
            label: '公告审核'
          }, {
            id: 5,
            label: '麦位名称审核'
          }, {
            id: 6,
            label: '贴纸文字审核'
          }, {
            id: 4,
            label: '节目单审核'
          }, {
            id: 7,
            label: '视频评论审核'
          }]
        }
      ],
      data: [
        {
          checktype: 1,
          account: 'tangtang',
          nickName: '糖糖',
          textContent: "欢迎大家来到我的直播间",
          checkstatus: 1,
          createtime: "2023-06-25 10:00:00",
          checktime: "2023-06-25 10:58:08",
          handler: '张三丰'
        }
      ],
      themeColorConfig,
      "columns": [{
        "show": false,
        "title": "审核类型",
        "dataIndex": "checktype",
        "key": "checktype",
        "fixed": false,
        "align": "left",
        "ellipsis": true,
        "type": "text",
        "scopedSlots": {
          "customRender": "checktype"
        }
      }, {
        "show": false,
        "title": "账号",
        "dataIndex": "account",
        "key": "1687331232135",
        "sorter": false,
        "type": "text",
        "fixed": false,
        "align": "left",
        "scopedSlots": {
          "customRender": "account"
        }
      }, {
        "show": false,
        "title": "昵称",
        "dataIndex": "nickName",
        "key": "1687331042023",
        "sorter": false,
        "type": "text",
        "fixed": false,
        "align": "left",
        "scopedSlots": {
          "customRender": "nickName"
        }
      }, {
        "show": true,
        "title": "文字内容",
        "dataIndex": "textContent",
        "key": "textContent",
        "sorter": false,
        "type": "text",
        "fixed": false,
        "align": "left",
        "scopedSlots": {
          "customRender": "textContent"
        },
        "width": 300
      }, {
        "show": true,
        "title": "审核状态",
        "dataIndex": "checkstatus",
        "key": "checkstatus",
        "sorter": false,
        "type": "tag",
        "fixed": false,
        "align": "left",
        "scopedSlots": {
          "customRender": "checkstatus"
        }
      }, {
        "show": false,
        "title": "创建时间",
        "dataIndex": "createtime",
        "key": "1687331345092",
        "sorter": false,
        "type": "createtime",
        "fixed": false,
        "align": "left",
        "scopedSlots": {
          "customRender": "createtime"
        }
      }, {
        "show": false,
        "title": "审核时间",
        "dataIndex": "checktime",
        "key": "1687331345092",
        "sorter": false,
        "type": "checktime",
        "fixed": false,
        "align": "left",
        "scopedSlots": {
          "customRender": "checktime"
        }
      }, {
        "show": false,
        "title": "处理人",
        "dataIndex": "handler",
        "key": "1687331395023",
        "sorter": false,
        "type": "text",
        "fixed": false,
        "align": "left",
        "scopedSlots": {
          "customRender": "age"
        }
      }, {
        "show": true,
        "title": "操作",
        "dataIndex": "operation",
        "key": "operation",
        "fixed": false,
        "align": "left",
        "ellipsis": true,
        "type": "operation",
        "width": 220,
        "scopedSlots": {
          "customRender": "operation"
        }
      }],
      "fieldName_3817": [{
        "aid": "1",
        "name": "选项1"
      }, {
        "aid": "2",
        "name": "选项2"
      }],
      "fieldName_2077": [{
        "aid": "1",
        "name": "选项1"
      }, {
        "aid": "2",
        "name": "选项2"
      }]
    };

  },
  filters: {
    iconType(type) {
      let str = ''
      switch (type) {
        case 'user': str = 'user';
          break;
        case 'fans': str = 'team';
          break;
        case 'Live': str = 'video-camera';
          break;
        case 'other': str = 'profile';
          break;
      }
      return str
    }
  },
  computed: {
  },
  created() {

  },
  mounted() {

  },
  beforeDestroy() {

  },
  watch: {},
  methods: {
    copyText,
    mixinTableItemChange,
    in_array,
    themeColorInit,
    isEmpty,
    handleChange(e) {
      console.log('eee', e)
    },
    /** 查询部门列表 */
    getList() {
      this.loading = true
      listPost(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        checktype: undefined,
        checkstatus: '',
        account: '',
        status: '',
        nickName: '',
        textContent: '',
        createtime: '',
        handler: ''
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.postId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleCheck(row) {
      var that = this
      const postIds = row.postId || this.ids
      delPost(postIds).then(() => {
        that.onSelectChange([], [])
        that.getList()
        that.$message.success(
          '删除成功',
          3
        )
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('system/post/export', {
            ...that.queryParam
          }, `post_${new Date().getTime()}.xlsx`)
        },
        onCancel() { }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import 'https://zhongtai.zhongbenruanjian.com/static/diypaaspc/css/diy-pass-pc.css';

.antdVue {
  width: 100%;
  background: #fff;
  background-size: 100% auto !important;
}

#antdVue .heartcheckManagementtextChecklist_flex_0 {
  background: #fff;
  width: 100%;
  min-height: 100px;
}

#antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c1_c5_c0_c1 {
  background: var(--benbenbgColor0);
  background-size: 100% auto !important;
  margin-bottom: 4px;
}

#antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c1_c5_c0_c0 {
  margin: 0px 10px 4px 0px;
}

#antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c1_c5_c0 {
  width: 100%;
}

#antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c1_c0_c0 {
  font-size: 14px;
}

#antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c1 {
  background: #fff;
  width: 100%;
}

#antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c0_c5_c0 {
  width: 100%;
}

#antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c0_c3_c0 {
  font-size: 14px;
}

#antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c0_c2_c0 {
  font-size: 14px;
}

#antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c0_c1_c0 {
  font-size: 14px;
}

#antdVue .heartcheckManagementtextChecklist_fd0_0_c0_c0 {
  background: #fff;
  width: 100%;
}

#antdVue .heartcheckManagementtextChecklist_fd0_0_c0 {
  background: #fff;
  width: 100%;
  min-height: 80px;
}

#antdVue .heartcheckManagementtextChecklist_fd0_0 {
  width: 100%;
}

#antdVue .heartcheckManagementtextChecklist_flex_1 {
  background: #fff;
  width: 100%;
  min-height: 100px;
}

#antdVue .heartcheckManagementtextChecklist_fd1_0_c3 {
  width: 500%;
}

#antdVue .heartcheckManagementtextChecklist_fd1_0_c2 {
  width: 100%;
}

#antdVue .heartcheckManagementtextChecklist_fd1_0_c1_c0 {
  background: var(--benbenbgColor3);
  background-size: 100% auto !important;
  color: var(--benbenFontColor3);
}

#antdVue .heartcheckManagementtextChecklist_fd1_0_c1 {
  width: 100%;
}

#antdVue .heartcheckManagementtextChecklist_fd1_0_c0 {
  width: 100%;
}

#antdVue .heartcheckManagementtextChecklist_fd1_0 {
  width: 100%;
}

#antdVue .heartcheckManagementtextChecklist_flex_2 {
  background: #fff;
  width: 100%;
  min-height: 100px;
}
</style>