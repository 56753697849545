import { render, staticRenderFns } from "./textChecklist.vue?vue&type=template&id=639b681a&scoped=true"
import script from "./textChecklist.vue?vue&type=script&lang=js"
export * from "./textChecklist.vue?vue&type=script&lang=js"
import style0 from "./textChecklist.vue?vue&type=style&index=0&id=639b681a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639b681a",
  null
  
)

export default component.exports